import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBadge, MtsLink, MtsDivider } from '@mts-ds/components-react';

import { IUser, INotificationClass } from '../../../../../../models/types';
import { IData } from '../../../../../../models/infrastructureUsers';
import { paths } from '../../../../../Root/paths';
import getFullName from '../../../../../../utils/getFullName';
import { getUserStatuses } from '../../utils';

import Typography from '../../../../../../components_/Typography';
import Tooltip from '../../../../../../components_/Tooltip';
import Icon from '../../../../../../components_/Icon';
import NotificationIcon from '../../../../../../components_/Notification/NotificationIcon';

const useStyles = makeStyles(({ spacing, getColor, getShadow }) => ({
  info: {
    marginTop: spacing(1),
    marginLeft: spacing(8),
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },
  label: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  pulseLabel: {
    color: getColor('icon-secondary')
  },
  states: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },
  firstState: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  otherStates: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  tooltip: {
    '--color-background-inverted': getColor('constant-greyscale-0'),
    '--color-text-inverted': getColor('text-primary'),
    borderRadius: 8,
    boxShadow: getShadow('low')
  },
  tooltipLabel: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },
  protections: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(2)
    }
  },
  divider: {
    margin: spacing(2, 0)
  }
}));

enum HealthTypes {
  PULSE = 'PULSE',
  DEVICE_CHARGE = 'DEVICE_CHARGE',
  DEVICE_IS_CHARGING = 'DEVICE_IS_CHARGING'
}

interface IProps {
  user: IUser;
  data: IData;
  isTabularNumberUsed: boolean;
  isPulseUsed: boolean;
  isProtectionsUsed: boolean;
}

interface IParams {
  officeId: string;
}

const Card: FC<IProps> = ({ user, data, isTabularNumberUsed, isPulseUsed, isProtectionsUsed }) => {
  const {
    descr,
    tabularNumber,
    phone,
    lead,
    protectionsList: userProtectionIds,
    trackInfo,
    charge
  } = user;

  const { states = [], measurements = [] } = trackInfo || {};
  const { value: chargePrecent } =
    (measurements && measurements.find(({ type }) => type === HealthTypes.DEVICE_CHARGE)) || {};
  const { precent: precentCharge } = charge || {};
  const { officeId } = useParams<IParams>();

  // const testStatuses: any = [
  //   {
  //     ntfy: {
  //       class: 'NotificationImmobilized',
  //       classFriendly: 'Обездвижен',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationInformational',
  //       classFriendly: 'Информационное сообщение',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationForgottenProtection',
  //       classFriendly: 'Забытое СИЗ',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationWorkEnd',
  //       classFriendly: 'Окончание рабочей смены',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationEnteringDangerZone',
  //       classFriendly: 'Вхождение в опасную зону',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   }
  // ];

  // MOCK
  const [firstState, ...otherStates] = getUserStatuses(states);
  const isFirstState = Boolean(firstState);
  const isOtherStates = Boolean(otherStates.length);

  const { protections: dataProtections } = data;

  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);

  const handleOpenClick = () => {
    setOpen(true);
  };

  const handleLeadClick = () => {
    if (lead) {
      history.push(paths.map.office.users.show(lead.username));
    }
  };

  const leadFullName = lead && getFullName(lead, false);

  const protections = isProtectionsUsed
    ? dataProtections.filter(dataProtection => userProtectionIds.includes(dataProtection.id))
    : [];

  const { value: pulse = '--' } = isPulseUsed
    ? (measurements && measurements.find(({ type }) => type === HealthTypes.PULSE)) || {}
    : {};
  // const { precent: charge = '--' } = charge;
  // const { Value: charge = '--' } =
  //   measurements.find(({ Type }) => Type === HealthTypes.DEVICE_CHARGE) || {};

  return (
    <>
      <Grid className={classes.info}>
        {isTabularNumberUsed && Boolean(tabularNumber) && (
          <MtsBadge size="s">{`#${tabularNumber}`}</MtsBadge>
        )}

        {isOpen && (
          <>
            <Grid container justify="space-between" alignItems="center">
              {/*<Grid item>*/}
              {/*  <Grid container alignItems="center" className={classes.label}>*/}
              {/*    <Icon name="label" size="s" color="constant-blueberry-normal" />*/}
              {/*    <Typography variant="p3Medium">--</Typography>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}

              <Grid item>
                <Grid container alignItems="center" className={classes.label}>
                  {Number(precentCharge || chargePrecent) ? (
                    Number(precentCharge || chargePrecent) <= 20 ? (
                      <Icon name="batteryLow" size="s" color="constant-raspberry-normal" />
                    ) : (
                      <Icon name="battery" size="s" color="constant-apple-normal" />
                    )
                  ) : (
                    <Icon name="battery" size="s" color="constant-greyscale-400" />
                  )}
                  <Typography variant="p3Medium">
                    {Number(precentCharge || chargePrecent) || '--'}%
                  </Typography>
                </Grid>
              </Grid>
              {isPulseUsed && (
                <Grid item>
                  <Grid container alignItems="center" className={classes.label}>
                    <Icon name="heart" size="s" color="constant-orange-normal" />
                    <Typography variant="p3Medium">
                      {pulse} <span className={classes.pulseLabel}>уд./мин</span>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {Boolean(phone) && (
              <Grid container alignItems="center" className={classes.label}>
                <Icon name="phone" size="s" color="text-secondary" />
                <Typography variant="p3Regular">{phone}</Typography>
              </Grid>
            )}

            {Boolean(descr) && (
              <Grid>
                <Typography variant="p3Regular" color="text-secondary">
                  {descr}
                </Typography>
              </Grid>
            )}
          </>
        )}

        {!isOpen && (
          <Grid>
            <Typography variant="p2Regular">
              <MtsLink onClick={handleOpenClick}>Подробнее...</MtsLink>
            </Typography>
          </Grid>
        )}

        <Grid className={classes.states}>
          {isFirstState && (
            <Grid container alignItems="center" wrap="nowrap" className={classes.firstState}>
              <Grid item>
                <NotificationIcon
                  notificationClass={firstState.ntfy.class as INotificationClass}
                  size="m"
                />
              </Grid>
              <Grid item>
                <Typography variant="p3Medium">{firstState.ntfy.classFriendly}</Typography>
              </Grid>
              <Grid item>
                <MtsBadge size="s">{moment(firstState.ntfy.time).format('HH:mm')}</MtsBadge>
              </Grid>
            </Grid>
          )}

          {isOtherStates && (
            <Grid container className={classes.otherStates}>
              {otherStates.map(({ ntfy: { class: classId, time, classFriendly } }) => (
                <Tooltip
                  key={classId}
                  label={
                    <Grid className={classes.tooltipLabel}>
                      <MtsBadge size="s">{moment(time).format('HH:mm')}</MtsBadge>
                      <Typography variant="p2Medium">{classFriendly}</Typography>
                    </Grid>
                  }
                  className={classes.tooltip}
                >
                  <NotificationIcon notificationClass={classId as INotificationClass} size="s" />
                </Tooltip>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      <MtsDivider className={classes.divider} />

      {Boolean(leadFullName) && (
        <>
          <Grid container alignItems="center" className={classes.label}>
            <Typography variant="p2Medium">Руководитель</Typography>
            <Typography variant="p2Regular">
              <MtsLink onClick={handleLeadClick}>{leadFullName}</MtsLink>
            </Typography>
          </Grid>

          <MtsDivider className={classes.divider} />
        </>
      )}
      {isProtectionsUsed && (
        <>
          <Grid className={classes.protections}>
            <Grid container alignItems="center" className={classes.label}>
              <Typography variant="p1Bold">Обязательные СИЗ</Typography>
              <MtsBadge type="counter" size="s" counterValue={userProtectionIds.length} />
            </Grid>
            {Boolean(protections.length) && (
              <Grid container spacing={1}>
                {protections.map(({ id, name }) => (
                  <Grid item key={id}>
                    <MtsBadge>{name}</MtsBadge>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          <MtsDivider className={classes.divider} />
        </>
      )}
    </>
  );
};

export default Card;
