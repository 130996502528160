import React, { FC } from 'react';
import { renderToString } from 'react-dom/server';
import { divIcon, MarkerCluster } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { makeStyles, Grid } from '@material-ui/core';

import { mapColor } from '../../../theme';
import { UserStatusPriority } from '../../../models/types';

const useStyles = makeStyles(() => ({
  point: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: mapColor.darkApple,
    width: 28,
    height: 28,
    fontWeight: 500,
    color: mapColor.white
  },
  pulse: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: mapColor.darkApple,
    width: 28,
    height: 28,
    fontWeight: 500,
    color: mapColor.white,
    opacity: 0.5,
    animation: '$pulseEffect 1s infinite reverse'
  },
  '@keyframes pulseEffect': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(2)'
    }
  },
  count: {
    borderRadius: '50%',
    width: 16,
    height: 16
  }
}));

const UserMarkerGroup: FC = ({ children }) => {
  const classes = useStyles();

  const renderIcon = (count: number, isStates: UserStatusPriority) => (
    <>
      <Grid
        className={isStates === UserStatusPriority.FOCUSED ? classes.pulse : undefined}
        style={
          isStates === UserStatusPriority.FOCUSED
            ? { backgroundColor: mapColor.mtsRed }
            : isStates === UserStatusPriority.NORMAL
            ? { backgroundColor: mapColor.lightBanana }
            : { backgroundColor: mapColor.darkApple }
        }
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.point}
        style={
          isStates === UserStatusPriority.FOCUSED
            ? { backgroundColor: mapColor.mtsRed }
            : isStates === UserStatusPriority.NORMAL
            ? { backgroundColor: mapColor.lightBanana }
            : { backgroundColor: mapColor.darkApple }
        }
      >
        <Grid item container justify="center" alignItems="center" className={classes.count}>
          {count}
        </Grid>
      </Grid>
    </>
  );

  const getHighestPriority = (priorities: any[]): UserStatusPriority => {
    let highestPriority: UserStatusPriority = UserStatusPriority.HIDDEN;
    for (const status of priorities) {
      if (
        status.options.children.props.user.trackInfo.states &&
        status.options.children.props.user.trackInfo.states.some(
          (child: { priority: UserStatusPriority }) => child.priority === UserStatusPriority.FOCUSED
        )
      ) {
        highestPriority = UserStatusPriority.FOCUSED;
        break;
      } else if (
        status.options.children.props.user.trackInfo.states &&
        status.options.children.props.user.trackInfo.states.some(
          (child: { priority: UserStatusPriority }) => child.priority === UserStatusPriority.NORMAL
        )
      ) {
        highestPriority = UserStatusPriority.NORMAL;
        break;
      } else {
        highestPriority = UserStatusPriority.HIDDEN;
      }
    }
    return highestPriority;
  };

  const icon = (cluster: MarkerCluster) => {
    const markers = cluster.getAllChildMarkers();
    const isStates = getHighestPriority(markers);
    const count = cluster.getChildCount();

    return divIcon({
      iconSize: undefined,
      html: renderToString(renderIcon(count, isStates))
    });
  };

  return (
    <MarkerClusterGroup maxClusterRadius={40} iconCreateFunction={icon}>
      {children}
    </MarkerClusterGroup>
  );
};

export default UserMarkerGroup;
